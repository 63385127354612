/* gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX */

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.scrollbar-very-thin::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
